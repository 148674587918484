import NotFound from "@/pages/NotFound"
import Main from "@/pages/Main"
import Contact from "@/pages/Contact"

export default [
  {
    path: "/",
    name: "main",
    component: Main
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  },
  {
    path: "*",
    name: "not-found",
    component: NotFound
  }
]
