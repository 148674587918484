<template>
  <div>
    <div class="panel">
      <div class="panel-block">
        Sygnalista to osoba, która z uwagi na posiadane informacje dokonuje zgłoszenia zauważonych nieprawidłowości. Status sygnalisty uzyska osoba, która dokonuje zgłoszenia różnego
        rodzaju nieprawidłowości, które powinny mieć oparcie w faktach, a które stanowić mogą działania lub zaniechania niezgodne z prawem a przez to, w interesie publicznym jest, aby w
        odpowiedni sposób identyfikować i wykrywać takie zachowania a także przeciwdziałać im na przyszłość.
      </div>
    </div>
    <h5 class="has-text-centered">Zgłoszenie nieprawidłowości może nastąpić poprzez odpowiedź na poniższe pytania gwarantując usprawnienie jej przetworzenia.</h5>
    <stage1 class="my-2" @response="stage2 = $event" />
    <transition name="fade" appear>
      <component :is="stage2" class="my-2" @response="saveResponse($event)" />
    </transition>
  </div>
</template>

<script>
  export default {
    name: "Main",
    components: {
      Stage1: () => import("@/components/questions/Stage1")
    },
    data() {
      return {
        stage2: null
      }
    },
    methods: {
      saveResponse() {
      }
    }
  }
</script>

<style scoped></style>
