import Vue from "vue"
import VueI18n from "vue-i18n"
import VueRouter from "vue-router"
import routes from "./routes"
import Vuelidate from "vuelidate"
import {register} from "register-service-worker"
import Buefy from "buefy"
import VueSweetAlert from "vue-sweetalert"
import moment from "moment"
import App from "./App"
import VueApollo from 'vue-apollo';
import * as Sentry from "@sentry/vue"
import {Integrations} from "@sentry/tracing"
import {apolloClient} from "@/vue-apollo"

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(Buefy)
Vue.use(VueSweetAlert)

Vue.config.productionTip = false
Vue.prototype.moment = moment
Vue.prototype.$reCAPTCHA = "6Le3GCIdAAAAAJEAG-a9qnkUEUIQF3RDB1YUIJsk"

function loadLocaleMessages() {
  const locales = require.context("./i18n", true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: navigator.language.substring(0, 2),
  fallbackLocale: "en",
  messages: loadLocaleMessages()
})

const router = new VueRouter({
  routes
})

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB")
    },
    registered() {
      console.log("Service worker has been registered.")
    },
    cached() {
      console.log("Content has been cached for offline use.")
    },
    updatefound() {
      console.log("New content is downloading.")
    },
    updated() {
      console.log("New content is available; please refresh.")
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.")
    },
    error(error) {
      console.error("Error during service worker registration:", error)
    }
  })
}

if(process.env.VUE_APP_SENTRY_DNS){
  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "sygnalista.put.poznan.pl", /^\//]
      })
    ],
    ignoreErrors: ["Request failed with status code 401"],
    tracesSampleRate: 1.0
  })
}

new Vue({
  router,
  i18n,
  apolloProvider,
  render: h => h(App)
}).$mount("#app")
