import {ApolloClient, HttpLink, InMemoryCache} from "@apollo/client/core"

const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_ENDPOINT || 'https://sygnalista.put.poznan.pl/graphql'
})

 export const apolloClient = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true
});
