<template>
  <div></div>
</template>

<script>
  export default {
    name: "Contact"
  }
</script>

<style scoped></style>
